import { child } from "firebase/database"
import type { DatabaseReference } from "firebase/database"
import { Ref, computed } from "@vue/composition-api"

import useJeopardAiBaseRef from "./useJeopardAiBaseRef"

export default function useJeopardAiRootRef(round: Ref<number>) {
  const { ref: baseRef } = useJeopardAiBaseRef()

  const rootRef = computed<DatabaseReference | undefined>(() => {
    if (baseRef.value == null) return undefined
    return child(baseRef.value, `rounds/${round.value}`)
  })

  return { ref: rootRef }
}

import {
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  computed
} from "@vue/composition-api"

import { onValue } from "firebase/database"

import type { Unsubscribe, DatabaseReference } from "firebase/database"

import useJeopardAiStateRef from "./useJeopardAiStateRef"
import useJeopardAiRound from "./useJeopardAiRound"

import type { State } from "../types"

let unsubscribe: Unsubscribe

const { round } = useJeopardAiRound()

const { ref: stateRef } = useJeopardAiStateRef(round)

const count = ref(0)
const state = ref<State | undefined>()

const enabled = computed(() => count.value > 0)

const databaseRef = computed<DatabaseReference | undefined>(() => {
  if (enabled.value === false) return undefined

  return stateRef.value
})

watch(databaseRef, value => {
  if (unsubscribe != null) unsubscribe()
  if (value == null) return

  unsubscribe = onValue(value, snapshot => {
    state.value = <State | undefined>snapshot.val()
  })
})

export default function useJeopardAiState() {
  onMounted(() => {
    count.value++
  })

  onBeforeUnmount(() => {
    count.value--
  })

  return { state }
}

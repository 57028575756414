





















































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  toRefs,
  ref
} from "@vue/composition-api"
import { sample } from "lodash"

import Medal from "./Medal.vue"

const IMG_1 = require("@/assets/jeopardai/robot-1.gif")
const IMG_2 = require("@/assets/jeopardai/character-1.gif")
const IMG_3 = require("@/assets/jeopardai/character-2.gif")
const IMG_4 = require("@/assets/jeopardai/character-3.gif")
const IMG_5 = require("@/assets/jeopardai/character-4.gif")
const IMG_6 = require("@/assets/jeopardai/character-5.gif")

export default defineComponent({
  name: "JeopardAiChallengeResultCard",
  components: { Medal },
  props: {
    team: {
      type: Object,
      required: true
    },
    flipped: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { team } = toRefs(props)

    const robots = [IMG_1]
    const people = [IMG_2, IMG_3, IMG_4, IMG_5, IMG_6]

    const images = computed(() => {
      if (team.value?.name === "ai") return robots

      return people
    })

    const image = ref(images.value[0])

    let interval

    onMounted(() => {
      interval = setInterval(function () {
        image.value =
          sample(images.value.filter(string => string !== image.value)) ??
          images.value[0]
      }, 3000)
    })

    onBeforeUnmount(() => {
      clearInterval(interval)
    })

    return { image }
  }
})

import { computed } from "@vue/composition-api"
import type { Ref } from "@vue/composition-api"

import { child } from "firebase/database"

import useJeopardAiRootRef from "./useJeopardAiRootRef"

export default function useJeopardAiStateRef(round: Ref<number>) {
  const { ref: rootRef } = useJeopardAiRootRef(round)
  const ref = computed(() => {
    if (rootRef.value == null) return undefined
    return child(rootRef.value, "state")
  })

  return { ref }
}

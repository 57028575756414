import { computed, watch } from "@vue/composition-api"

import { chain } from "lodash"

import useTeams from "@/use/useTeams"
import useStore from "@/use/useStore"
import Team from "@shared/Team"

import useIsViewerHostLike from "./useIsViewerHostLike"
import useJeopardAiState from "./useJeopardAiState"
import useJeopardAiAnswers from "./useJeopardAiAnswers"

import { Module } from "../enums"

export default function useJeopardAiResults() {
  const { isViewerHostLike } = useIsViewerHostLike()
  const { teams } = useTeams()
  const { store } = useStore()
  const { state } = useJeopardAiState()

  const { answers } = useJeopardAiAnswers()

  const module = computed(() => state.value?.module ?? Module.CATEGORIES)

  watch(
    module,
    value => {
      store.commit("JeopardAi/UPDATE_MODULE", value)
    },
    { immediate: true }
  )

  const isTeamFlipped = computed(
    () => team => Boolean(state.value?.flipped?.[team.id])
  )

  const challenges = computed(() => {
    if (module.value !== Module.WRAPUP) return []

    const values = chain(answers.value)
      .filter(answer => answer.challenge)
      .groupBy(answer => answer.questionId)
      .map((value, key) => {
        return {
          questionId: key,
          correct: value.some(answer => answer.correct)
        }
      })
      .value()

    const teams = [
      {
        id: "players",
        name: "players",
        total: values.filter(({ correct }) => correct).length
      },
      {
        id: "ai",
        name: "ai",
        total: values.filter(({ correct }) => !correct).length
      }
    ]

    return teams
      .map(team => ({
        ...team,
        active: team.id === currentTeamId.value,
        flipped: isTeamFlipped.value(team),
        showTag: isViewerHostLike.value || isTeamFlipped.value(team)
      }))
      .sort((a, b) => b.total - a.total)
  })

  const currentTeamId = computed(
    () => store.getters.gameStatus?.flippedTeamID as string | undefined
  )

  function getTeamTotal(team) {
    return answers.value.reduce((acc, val) => {
      if (val.teamId !== team?.id) return acc
      if (val.amount != null) return acc + val.amount

      return acc
    }, 0)
  }

  const sorted = computed(() => {
    const values = Team.normalize(teams.value)
      .filter(team => !Team.isSpecialPurpose(team))
      .map(team => ({
        ...team,
        active: team.id === currentTeamId.value,
        flipped: isTeamFlipped.value(team),
        showTag: isViewerHostLike.value || isTeamFlipped.value(team),
        total: getTeamTotal(team)
      }))
      .sort((a, b) => b.total - a.total)

    return values
  })

  return {
    module,
    isTeamFlipped,
    isViewerHostLike,
    teams: sorted,
    challenges,
    state
  }
}

import { child } from "firebase/database"
import type { DatabaseReference } from "firebase/database"
import { computed } from "@vue/composition-api"

import useJeopardAiBaseRef from "./useJeopardAiBaseRef"

export default function useJeopardAiRoundRef() {
  const { ref: baseRef } = useJeopardAiBaseRef()

  const rootRef = computed<DatabaseReference | undefined>(() => {
    if (baseRef.value == null) return undefined
    return child(baseRef.value, `round`)
  })

  return { ref: rootRef }
}

import { ref, watch } from "@vue/composition-api"

import { onValue } from "firebase/database"

import type { Unsubscribe } from "firebase/database"

import useJeopardAiRoundRef from "./useJeopardAiRoundRef"

let unsubscribe: Unsubscribe

const { ref: roundRef } = useJeopardAiRoundRef()

const round = ref<number>(1)

watch(roundRef, value => {
  if (unsubscribe != null) unsubscribe()
  if (value == null) return

  unsubscribe = onValue(value, snapshot => {
    round.value = snapshot.val() ?? 1
  })
})

export default function useJeopardAiRound() {
  return { round }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jeopardai-challenge-result-card",
      class: { "jeopardai-challenge-result-card--active": _vm.current },
      attrs: { "d-flex": "" },
    },
    [
      _c(
        "transition",
        {
          attrs: {
            name: "jeopardai-challenge-result-card__transition",
            mode: "out-in",
          },
        },
        [
          _vm.flipped
            ? _c(
                "div",
                {
                  key: "summary",
                  staticClass:
                    "jeopardai-challenge-result-card__front-side jeopardai-challenge-result-card__card",
                },
                [
                  _c(
                    "Medal",
                    {
                      staticClass:
                        "jeopardai-challenge-result-card__front-side-medal",
                      attrs: { variant: _vm.position + 1 },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.position === 1 ? "1st" : null) + " "
                      ),
                    ]
                  ),
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "jeopardai-challenge-result-card__rotate",
                        mode: "out-in",
                      },
                    },
                    [
                      _c("img", {
                        key: _vm.image,
                        staticClass: "jeopardai-challenge-result-card__image",
                        attrs: { src: _vm.image },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "jeopardai-challenge-result-card__won" },
                    [
                      _c("div", [_vm._v("WON")]),
                      _c("div", [_vm._v(_vm._s(_vm.team.total))]),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  key: "unflipped",
                  staticClass:
                    "jeopardai-challenge-result-card__back-side jeopardai-challenge-result-card__card",
                  attrs: { "d-flex": "" },
                },
                [
                  _c("Medal", {
                    staticClass:
                      "jeopardai-challenge-result-card__back-side-medal",
                    attrs: { variant: 2 },
                  }),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }